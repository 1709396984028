
<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="appData === undefined">
      <h4 class="alert-heading">Error fetching {{ modulename }} details</h4>
      <div class="alert-body">
        <b-link class="alert-link" :to="{ name: GENAPP_APP_STORE_MODULE_NAME }">
          No {{ modulename }} details found
        </b-link>
      </div>
    </b-alert>
    <b-row v-if="appData" class="invoice-preview">
      <b-col cols="12">
        <b-card no-body class="invoice-preview-card">
          <b-card-body class="d-print-block d-none invoice-padding pb-0">
            <div
              class="d-flex justify-content- flex-md-row flex-column invoice-spacing mt-0"
            >
              <div>
                <div class="logo-wrapper">
                  <logo style="height: 10vw; wipricing: 10vw" />
                  <h3 class="text-primary invoice-logo">FRP</h3>
                </div>
              </div>
            </div>
          </b-card-body>
          <b-card-body class="invoice-padding pb-0">
            <div>
              <h5>{{ modulename }} {{ $t("fields.details") }}</h5>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th style="width: 60%">{{ $t("fields.title") }}</th>
                  <th style="width: 40%" class="text-right">{{ $t("fields.amount") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colspan="2">
                    <div class="row" v-b-toggle.asset>
                      <div class="col-8">{{ $t("navigation.assets") }}</div>
                      <div class="col-4 text-right text-success">
                        ₹ {{ asset }}
                      </div>
                    </div>
                    <b-collapse id="asset" class="mt-2">
                      <table class="table">
                        <tbody>
                          <tr>
                            <th style="width: 60%">{{ $t("navigation.cash") }}</th>
                            <td class="text-right text-success">
                              ₹ {{ appData.tempx.asset["cash"] || 0 }}
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 60%">{{ $t("fields.wallet") }}</th>
                            <td class="text-right text-success">
                              ₹ {{ appData.tempx.asset["wallet"] || 0 }}
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 60%">{{ $t("navigation.bank") }}</th>
                            <td class="text-right text-success">
                              ₹ {{ appData.tempx.asset["bank"] || 0 }}
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 60%">{{ $t("navigation.receivables") }}</th>
                            <td class="text-right text-success">
                              ₹ {{ appData.tempx.asset["recievables"] || 0 }}
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 60%">{{ $t("navigation.fixeddeposits") }}</th>
                            <td class="text-right text-success">
                              ₹ {{ appData.tempx.asset["fixeddeopsit"] || 0 }}
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 60%">{{ $t("navigation.recurringdeposits") }}</th>
                            <td class="text-right text-success">
                              ₹ {{ appData.tempx.asset["recuringdeopsit"] || 0 }}
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 60%">{{ $t("navigation.mutualfunds") }}</th>
                            <td class="text-right text-success">
                              ₹ {{ appData.tempx.asset["financialassets_mf"] || 0 }}
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 60%">{{ $t("navigation.securites") }}</th>
                            <td class="text-right text-success">
                              ₹
                              {{ appData.tempx.asset["financialassets_securities"] || 0 }}
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 60%">NSC</th>
                            <td class="text-right text-success">
                              ₹ {{ appData.tempx.asset["financialassets_nsc"] || 0  }}
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 60%">{{ $t("navigation.retailgraduvity") }} </th>
                            <td class="text-right text-success">
                              ₹
                              {{
                                appData.tempx.asset[
                                  "financialassets_retirals_graduity"
                                ] || 0
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 60%">{{ $t("navigation.retirals") }} - NPS</th>
                            <td class="text-right text-success">
                              ₹
                              {{
                                appData.tempx.asset["financialassets_retirals_nps"] || 0
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 60%">{{ $t("navigation.insurance") }} - {{ $t("navigation.life") }}</th>
                            <td class="text-right text-success">
                              ₹ {{ appData.tempx.asset["insurancelife"] || 0 }}
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 60%">{{ $t("navigation.physicalassets") }} - {{ $t("navigation.realestate") }}</th>
                            <td class="text-right text-success">
                              ₹ {{ appData.tempx.asset["physicalassets_realestate"]  || 0 }}
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 60%">{{ $t("navigation.physicalassets") }} - {{ $t("navigation.automobile") }}</th>
                            <td class="text-right text-success">
                              ₹ {{ appData.tempx.asset["physicalassets_automobile"]  || 0 }}
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 60%">{{ $t("navigation.physicalassets") }} - {{ $t("navigation.preciousmet") }}</th>
                            <td class="text-right text-success">
                              ₹
                              {{ appData.tempx.asset["physicalassets_preciousmetals"] || 0 }}
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 60%">
                                {{ $t("navigation.physicalassets") }} - {{ $t("navigation.artsclotible") }}
                            </th>
                            <td class="text-right text-success">
                              ₹
                              {{
                                appData.tempx.asset["physicalassets_artsandcollectible"] || 0
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-collapse>
                  </th>
                </tr>
                <tr>
                  <th colspan="2">
                    <div class="row" v-b-toggle.liab>
                      <div class="col-8">{{ $t("navigation.liabilities") }}</div>
                      <div class="col-4 text-right text-danger">
                        ₹ {{ liab }}
                      </div>
                    </div>
                    <b-collapse id="liab" class="mt-2">
                      <table class="table">
                        <tbody>
                          <tr>
                            <th style="width: 60%">Accounts Payable</th>
                            <td class="text-right text-danger">
                              ₹ {{ appData.tempx.liab["liabilities_payables"] || 0 }}
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 60%">Personal Loans</th>
                            <td class="text-right text-danger">
                              ₹ {{ appData.tempx.liab["liabilities_personalloan"] || 0 }}
                            </td>
                          </tr>
                          
                          <tr>
                            <th style="width: 60%">Auto Loan</th>
                            <td class="text-right text-danger">
                              ₹ {{ appData.tempx.liab["liabilities_autoloan"] || 0 }}
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 60%">Credit Card Debt</th>
                            <td class="text-right text-danger">
                              ₹ {{ appData.tempx.liab["liabilities_creditcards"] || 0 }}
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 60%">Home Loan</th>
                            <td class="text-right text-danger">
                              ₹ {{ appData.tempx.liab["physicalassets_realestate"] || 0 }}
                            </td>
                          </tr>

                          <tr>
                            <th style="width: 60%">Consumer Loans</th>
                            <td class="text-right text-danger">
                              ₹ {{ appData.tempx.liab["liabilities_consumerloan"] || 0 }}
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 60%">Student Loans</th>
                            <td class="text-right text-danger">
                              ₹ {{ appData.tempx.liab["liabilities_studentloan"] || 0 }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-collapse>
                  </th>
                </tr>
                <tr>
                  <th colspan="2">
                    <div class="row">
                      <div class="col-8">{{ $t("fields.total") }}</div>
                      <div class="col-4 text-right" :class="totalasset>0 ? 'text-success' : 'text-danger'">
                        ₹ {{ totalasset }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tbody>
            </table>
          </b-card-body>
          <hr class="invoice-spacing" />
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
  <script>
import viewsidelist from "@/views/Component/viewsidelist.vue";
import {
  BAvatar,
  BRow,
  BCol,
  BAlert,
  BLink,
  BCardText,
  BCard,
  BTable,
  BCardHeader,
  VBToggle,
  BTableLite,
  BCardBody,
  BCollapse,
  BForm,
  BButton,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import DocumentList from "@/views/Component/DocumetsList/DocumentList.vue";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import StoreModule from "./StoreModule";
import useList from "./useList";
import { useToast } from "vue-toastification/composition";
import Logo from "@core/layouts/components/Logo.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCol,
    BCardHeader,
    BCollapse,
    BCard,
    BAvatar,
    viewsidelist,
    AppCollapse,
    AppCollapseItem,
    VBToggle,
    BRow,
    BCardBody,
    BCardText,
    BForm,
    BTable,
    BCardBody,
    BTableLite,
    BButton,
    ToastificationContent,
    BAlert,
    DocumentList,
    BLink,
    Logo,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {};
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "reports-networth";
    const modulename = "Networth";
    const modulenamesub = "networth";
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    const toast = useToast();
    const appData = ref(null);
    const serviceprov = ref(null);

    const printview = () => {
      window.print();
    };
    var totalasset = ref(0);
    var asset = ref(0);
    var liab = ref(0);
    const { deletefun } = useList();
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchData")
      .then((response) => {
        if (!response.data) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Couldnt find " + modulenamesub + " details",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          router
            .push({
              name: GENAPP_APP_STORE_MODULE_NAME,
            })
            .catch(() => {});
        }
        totalasset.value = 0;
        asset.value = 0;
        liab.value = 0;
        appData.value = response.data;
        for (let item in response.data.tempx.asset) {
            asset.value+= parseFloat(response.data.tempx.asset[item])
        }
        for (let item in response.data.tempx.liab) {
            liab.value = liab.value+parseFloat(response.data.tempx.liab[item]);
        }
        totalasset.value=asset.value-liab.value
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 404) {
          appData.value = undefined;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Couldnt find " + modulenamesub + " details",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else if (error.response.status === 500) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Input fields missing values",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else if (error.response.status === 422) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Please refresh your page",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
        router
          .push({
            name: GENAPP_APP_STORE_MODULE_NAME,
          })
          .catch(() => {});
      });
    return {
      GENAPP_APP_STORE_MODULE_NAME,
      deletefun,
      modulename,
      modulenamesub,
      appData,
      printview,
      serviceprov,
      totalasset,
        asset,
        liab,
    };
  },
  methods: {
    checkdeletefun(val) {
      var self = this;
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deletefun(val);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: this.modulename + " has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          self.$router.push({
            name: this.GENAPP_APP_STORE_MODULE_NAME,
          });
        }
      });
    },
  },
};
</script>

  <style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

  <style lang="scss">
@media print {
  a {
    color: black;
    text-decoration: none !important;
  }
  .d-print-block {
    display: block !important;
  }
  .content-header-right {
    display: none;
  }
  .invoice-actions {
    display: none;
  }
  .breadcrumbs-top {
    display: none;
  }
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  } // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-wipricing: 100%;
        flex-grow: 1;
      }
      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-wipricing: 50% !important;
            }
            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }
  }
}
</style>
